import React, { useState } from 'react'
import '../../../styles/sidebar.css'
import { Button, Layout } from 'antd'
import MenuList from './Sidebar/MenuList'
import { ChevronFirst, ChevronLast } from 'lucide-react'

import logo from '../../../assets/TE.png'

const { Header, Sider } = Layout

const Sidebar = ({userData, updateTitle}) => {
  const [collapsed, setCollapsed] = useState(false)
  return (
    <div>
      <div className={`${collapsed ? 'w-[80px]' : 'w-[280px]'}`}></div>
      <div className='fixed'>
        <Layout className='h-screen sticky'>
          <Sider collapsed={collapsed} trigger={null} className='sidebar border-r border-gray-100 shadow-lg' width={280} theme='light'>
            <Layout>
              <Header className={`p-0 bg-white flex items-center ${collapsed ? 'justify-center' : 'justify-between px-2'} `}>
                <img className={`overflow-hidden transition-all ${collapsed ? "w-0" : 'w-[7rem]'}`} src={logo} alt="logo" />
                <Button type='text' className='toggle' onClick={() => setCollapsed(!collapsed)} icon={collapsed ? <ChevronLast className="text-[var(--primary-color)]"/> : <ChevronFirst className="text-[var(--primary-color)]"/>}/>
              </Header>
            </Layout> 
            <MenuList userData={userData} collapsed={collapsed} updateTitle={updateTitle}/>
          </Sider>
        </Layout>
      </div>
    </div>
  )
}

export default Sidebar