import React, { useState } from 'react';
import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';

// Helper function to sanitize HTML content safely
const createMarkup = (html) => ({ __html: DOMPurify.sanitize(html) });

// Helper function to render image icon from API
const getIcon = (iconUrl, width, height) => (
  <img src={iconUrl} alt="icon" style={{ width, height }} />
);

const MenuList = ({ userData, collapsed, updateTitle }) => {
  const [selectedKey, setSelectedKey] = useState('');
  const navigate = useNavigate();

  const handleNavigation = (path, title, key) => {
    if (path) {
      navigate(path); // Navigate to the route
      updateTitle(title); // Update the title in the parent component
      setSelectedKey(key); // Set the selected key for highlighting
    }
  };

  const { userPages = [] } = userData?.userMenu || {};

  // Group userPages by category and create menu items
  const groupedPages = userPages.reduce((groups, page) => {
    const category = page.pageCategory || 'Other';
    if (!groups[category]) groups[category] = [];

    const menuItem = {
      key: page.pageId.toString(),
      icon: getIcon(page.pageIcon, 20, 20),
      label: (
        <p className='uppercase text-[var(--primary-color)] flex justify-between tracking-tight'>
          <span className='text-sm'>{page.pageTitle}</span>
          {collapsed && page.pageSubTitle && (
            <div dangerouslySetInnerHTML={createMarkup(page.pageSubTitle)} />
          )}
        </p>
      ),
      path: page.pageRoute,
      title: page.pageTitle,
      children: page.pageType === 'composite' ? page.pageSections.flatMap(section =>
        section.sectionApps.map(app => ({
          key: `app-${app.appId}`,
          label: <span className='capitalize text-xs pl-8'>{app.appTitle}</span>,
          path: app.appRoute,
          title: app.appTitle,
        }))
      ) : undefined
    };

    groups[category].push(menuItem);
    return groups;
  }, {});

  // Generate menuItems array from the grouped pages
  const menuItems = Object.keys(groupedPages).map(category => ({
    type: 'group',
    label: collapsed ? <hr className='mx-4' /> : <span>{category}</span>,
    children: groupedPages[category]
  }));

  // Handle all navigation at the Menu level
  const onMenuClick = (e) => {
    // Find the clicked item by its key
    const findItem = (items, key) => {
      for (let item of items) {
        if (item.key === key) return item;
        if (item.children) {
          const found = findItem(item.children, key);
          if (found) return found;
        }
      }
      return null;
    };

    const clickedItem = findItem(menuItems, e.key);

    if (clickedItem && clickedItem.path) {
      handleNavigation(clickedItem.path, clickedItem.title, clickedItem.key);
    }
  };

  return (
    <Menu
      theme="light"
      mode="inline"
      className="mt-8 flex flex-col gap-3 relative"
      items={menuItems}
      selectedKeys={[selectedKey]}
      onClick={onMenuClick} // Handle all clicks here
    />
  );
};

export default MenuList;
