import React, { createContext, useState, useContext } from "react";

// Create the context
const SelectedSiteContext = createContext();

// Create a provider component
export const SelectedSiteProvider = ({ children }) => {
  const [selectedSite, setSelectedSite] = useState(null);

  return (
    <SelectedSiteContext.Provider value={{ selectedSite, setSelectedSite }}>
      {children}
    </SelectedSiteContext.Provider>
  );
};

// Custom hook for consuming the context
export const useSelectedSite = () => {
  const context = useContext(SelectedSiteContext);
  if (!context) {
    throw new Error(
      "useSelectedSite must be used within a SelectedSiteProvider"
    );
  }
  return context;
};
