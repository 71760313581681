import React, { useEffect, useState } from "react";
import { DashboardLayoutComponent } from "@syncfusion/ej2-react-layouts";
import { useSelector } from "react-redux";
import { getUserData } from "../../features/auth/authSlice";
import connectSocket from "../../Socket/Socket";
import { useSelectedSite } from "../../features/contexts/SiteContext";
import Widget1 from "./Widget1"; // Import your widget component
import ReactDOMServer from 'react-dom/server';

const Dashboard = () => {
    const userData = useSelector(getUserData);
    const [dashboards, setDashboards] = useState([]);
    const [panels, setPanels] = useState([]);
    const { selectedSite } = useSelectedSite(); // State to track selected site
    const socket_project_path = process.env.REACT_APP_API_SOCKETIO_PROJECT_PATH;
    const cellSpacing = [5, 5];
    const resizeHandles = ["e-south-east", "e-east", "e-west", "e-north", "e-south"];

    const requestDashboardData = (socket, siteId) => {
        const payload = {
            request: "content",
            content: {
                type: "dashboards",
                dashboards: {
                    siteId,
                },
            },
        };

        // Emit with acknowledgment
        socket.emit("automation:mob:content:usr:v1", payload, (ack) => {
            if (!ack) {
                console.error("No acknowledgment received from server!");
                return;
            }

            if (ack.responseStatus === "failed") {
                console.error(ack.message?.text || "Error in server acknowledgment");
                return;
            }

            setDashboards(ack.data);
            console.log("Acknowledgment received:", ack);
        });
    };

    useEffect(() => {
        const socket = connectSocket(userData.token, socket_project_path, true);
        if (selectedSite) 
            requestDashboardData(socket, selectedSite);

    }, [userData.token, selectedSite, socket_project_path]);

    useEffect(() => {
        if (dashboards.length > 0) {
            // Use Dashboard 1 for now
            const dashboard1 = dashboards.find((d) => d.dashboardId === "dash01");
            if (dashboard1) {
                const dynamicPanels = dashboard1.widgets.map((widget, index) => ({
                    id: `panel-${index + 1}`,
                    sizeX: widget.widgetProperties.layout.sizeX,
                    sizeY: widget.widgetProperties.layout.sizeY,
                    row: widget.widgetProperties.layout.row,
                    col: widget.widgetProperties.layout.col,
                    content: ReactDOMServer.renderToString(<Widget1 widget={widget} />), // Pass the widget data for rendering
                }));

                setPanels(dynamicPanels);
            }
        }
    }, [dashboards]);

    console.log(panels);

    return (
        <div id="container" className="relative h-screen">
            <DashboardLayoutComponent
                id="defaultLayout"
                cellSpacing={cellSpacing}
                panels={panels}
                allowResizing={true}
                columns={5}
                resizableHandles={resizeHandles}
                resizeStop={() => console.log("Resize stop")}
                dragStop={() => console.log("Drag stop")}
            >
            </DashboardLayoutComponent>
        </div>
    );
};

export default Dashboard;
