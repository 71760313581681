import React, { useState, useEffect } from 'react';
import '../../../../styles/dropdown.css';
import { Dropdown, Button, Radio, Space } from 'antd';
import { RightOutlined, DownOutlined } from '@ant-design/icons';
import { useSelectedSite } from '../../../../features/contexts/SiteContext';

const ChooseSite = ({ userData }) => {
  const { selectedSite, setSelectedSite } = useSelectedSite(); // State to track selected site
  const [selectedOption, setSelectedOption] = useState('Default Site'); // State to track selected option label
  const [expand, setExpand] = useState({}); // Track expand state for projects


  useEffect(() => {
    // Find the site with "siteDefault": "A"
    let defaultSite = null;
    let defaultProjectName = null;

    userData?.userRoles?.forEach((role) => {
      role.roleProjects.forEach((project) => {
        project.projectSites.forEach((site) => {
          if (site.siteDefault === 'A') {
            defaultSite = site;
            defaultProjectName = project.projectName;
          }
        });
      });
    });

    // If a default site is found, set it as the selected site
    if (defaultSite) {
      setSelectedSite(defaultSite.siteId);
      setSelectedOption(`${defaultProjectName} - ${defaultSite.siteName}`);
    }
  }, [userData, setSelectedSite]);

  const toggleExpand = (projectId) => {
    setExpand((prevState) => ({
      ...prevState,
      [projectId]: !prevState[projectId], // Toggle the expansion state of the specific project
    }));
  };

  // Function to handle site selection
  const handleSiteSelection = (projectName, siteId, siteName) => {
    setSelectedSite(siteId); // Set selected site ID (to ensure only one selected)
    setSelectedOption(`${projectName} - ${siteName}`); // Update the display label
  };

  // Create the menu items for the dropdown
  const menuItems = userData.userRoles.flatMap((role) =>
    role.roleProjects.map((project) => ({
      key: project.projectId,
      label: (
        <button
          onClick={() => toggleExpand(project.projectId)}
          className="font-semibold cursor-pointer text-[var(--primary-color)] w-full flex items-center"
        >
          {project.projectName}{' '}
          {expand[project.projectId] ? (
            <DownOutlined className="ml-5 w-3" />
          ) : (
            <RightOutlined className="ml-5 w-3" />
          )}
        </button>
      ),
      type: 'group',
      children: project.projectSites.map((site) => ({
        key: site.siteId,
        className: expand[project.projectId] ? '' : 'site-item-collapsed',
        label: (
          <div
            key={site.siteId}
            onClick={() =>
              handleSiteSelection(project.projectName, site.siteId, site.siteName)
            }
            className={`transition-all overflow-hidden ease-in-out duration-200 bg-tr ${
              expand[project.projectId] ? 'max-h-[500px]' : 'max-h-0'
            }`}
          >
            <div className="flex items-center gap-2">
              <Radio checked={selectedSite === site.siteId} />
              <span>{site.siteName}</span>
            </div>
          </div>
        ),
      })),
    }))
  );

  return (
    <div>
      <Space>
        <Dropdown menu={{ items: menuItems }} trigger={['click']}>
          <Button>{selectedOption}</Button>
        </Dropdown>
      </Space>
    </div>
  );
};

export default ChooseSite;
