import React from "react";

const Widget1 = ({ widget }) => {
    const { title, description, component, widgetProperties } = widget;

    return (
        <div
            className="flex flex-col items-center justify-center h-full w-full p-4"
            style={{
                border: `2px solid ${widgetProperties.style.borderColor}`, // Dynamic border color
                backgroundColor: widgetProperties.style.card ? "#f9f9f9" : "transparent", // Card background
            }}
        >
            {/* Widget Title */}
            <h3 className="text-lg font-bold mb-2">{title}</h3>

            {/* Widget Description */}
            <p className="text-sm text-gray-500 mb-4">{description}</p>

            {/* Component Details */}
            <div className="text-center">
                {component.map((comp, index) => (
                    <div key={index} className="mb-2">
                        <h4 className="text-md font-semibold">{comp.componentDetails.componentName}</h4>
                        <p className="text-sm text-gray-600">{comp.componentDetails.componentDesc}</p>
                        <div className="flex flex-col gap-1 mt-2">
                            {comp.features.map((feature, idx) => (
                                <div
                                    key={idx}
                                    className="text-sm text-gray-800 flex justify-between gap-2"
                                >
                                    <span>{feature.featureDetails.featureDesc}:</span>
                                    <span className="font-semibold">
                                        {feature.featureDetails.featureUnitDesc}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Widget1;
