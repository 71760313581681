import React, { useEffect, useState } from 'react';

import Sidebar from '../components/Home/Global/Sidebar';
import Navbar from '../components/Home/Global/Navbar';

import { useDispatch, useSelector } from 'react-redux';
import { getUserData, setCredentials } from '../features/auth/authSlice';
import { Outlet } from 'react-router-dom';

const Home = () => {
  const dispatch = useDispatch();
  const userData = useSelector(getUserData); // Get user data from Redux state

  const [loading, setLoading] = useState(true);
  const [currentTitle, setCurrentTitle] = useState('DASHBOARD'); // State to hold the current title

  // Only run the effect if userData or token is missing
  useEffect(() => {
    const token = localStorage.getItem("_token");
    const userId = localStorage.getItem("userId");
    const storedUserData = JSON.parse(localStorage.getItem("userData"));

    // Check if Redux already has the token, userId, and userData
    if (!userData?.token && token && userId) {
      // Dispatch the setCredentials action to restore the state from localStorage
      dispatch(setCredentials({ userId, accessToken: token, userData: storedUserData }));
    }

    // Set loading to false once the token check is complete
    setLoading(false);
  }, [dispatch, userData]); // Dependency on userData

  // Function to update the title from Sidebar
  const updateTitle = (title) => {
    setCurrentTitle(title);
  };

  console.log(userData)

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="spinner"></div>
        <p className="text-[var(--primary-color)] text-center text-xs mt-1">Loading...</p>
      </div>
    );
  }

  return (
    <div className='relative flex gap-5 bg-slate-100'>
      <Sidebar userData={userData} updateTitle={updateTitle} />
      <div className='flex flex-col gap-5 w-full'>
        <Navbar userData={userData} currentTitle={currentTitle} />
        <Outlet />
      </div>
    </div>
  );
};

export default Home;
