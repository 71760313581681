import { io } from "socket.io-client";

export const connectSocket = (token, path, auto) => {
  const socket = io(process.env.REACT_APP_API_SOCKETIO_ENDPOINT, {
    autoConnect: auto,
    path: path,
    upgrade: false,
    reconnection: true, // Enable auto-reconnection
    reconnectionAttempts: Infinity, // Attempt to reconnect forever
    reconnectionDelay: 1000, // Start with a 1-second delay
    reconnectionDelayMax: 5000, // Maximum delay between reconnection attempts
    query: {
      token: token,
    },
    transports: ["websocket"], // use WebSocket first, if available
    rejectUnauthorized: false,
  });

  // Attach socket event listeners
  socket.on("connect", () => {
    console.log("Connected to Socket");
  });

  socket.on("connect_error", (err) => {
    console.error("Socket connection error:", err);
  });

  socket.on("disconnect", (reason) => {
    console.log("Disconnected from Socket:", reason);
  });

  socket.on("error", (err) => {
    console.error("Socket error:", err);
  });

  return socket; // Return socket if needed for further interaction
};

export default connectSocket;
