import React from 'react';

import ChooseSite from './Navbar/ChooseSite';
import ProfileDropdown from './Navbar/ProfileDropdown';

const Navbar = ({ userData, currentTitle }) => {
  return (
    <div className='w-full h-12 flex justify-between items-center mt-2'>
      {/* Left Nav */}
      <div className='w-4/5 flex items-center justify-start'>
        <p className='uppercase tracking-tight text-[var(--primary-color)] text-xl'>{currentTitle}</p>
      </div>

      {/* Right Nav */}
      <div className='flex gap-4 items-center mr-2'>  
        <ChooseSite userData={userData}/>
        <ProfileDropdown userData={userData}/>
      </div>
    </div>
  );
};

export default Navbar;
