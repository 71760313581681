import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import App from "./App.jsx";
import "./index.css";

import { store } from "./app/store";
import { Provider } from "react-redux";

import { HashRouter } from "react-router-dom";

import { registerLicense } from "@syncfusion/ej2-base";
import { SelectedSiteProvider } from "./features/contexts/SiteContext.js";

registerLicense(
  "ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5adkdjXnpZc3BdQmBf"
);

createRoot(document.getElementById("root")).render(
  <StrictMode>
    <Provider store={store}>
      <HashRouter>
        <SelectedSiteProvider>
          <App />
        </SelectedSiteProvider>
      </HashRouter>
    </Provider>
  </StrictMode>
);
