import Button from "../ui/Button"
import Input from "../ui/Input"

import taqalogo from '../assets/TE.png'

import React, {useRef, useState, useEffect} from 'react'

import { HandleLogin } from "../features/utils/Handlers"
import { useApirequestMutation } from "../features/auth/authApiSlice"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useSelectedSite } from "../features/contexts/SiteContext"

const Login = () => {
  const dispatch = useDispatch();
  
  const [apirequest, { isLoading }] = useApirequestMutation();
  const navigate = useNavigate();
  // Loading while fetching data from API

  const userRef = useRef()
  const errRef = useRef();

  const [user, setUser] = useState('')
  const [pwd, setPwd] = useState('')

  // States for handling errors
  const [errMsg, setErrMsg] = useState('')

  // States for handling password visibility
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(false);

  const [rememberMe, setRememberMe] = useState(false);

  const { setSelectedSite } = useSelectedSite(); // State to track selected site


  useEffect(() => {
    if (userRef.current)
      userRef.current.focus()
  }, [])

  useEffect(() => {
      setErrMsg('')
  }, [user, pwd])

  const handleSubmit = async (e) => {
    e.preventDefault()
    await HandleLogin({
      user,
      setUser,
      pwd,
      setPwd,
      setErrMsg,  // Passing setErrMsg correctly
      errRef,
      rememberMe,
      dispatch,
      apirequest,
      navigate,
      setSelectedSite
    });
  }

  const handleToggle = () => {
    if (type === 'password'){
       setIcon(true);
       setType('text')
    } else {
       setIcon(false)
       setType('password')
    }
 }

  const handleUserInput = (e) => setUser(e.target.value)
  const handlePwdInput = (e) => setPwd(e.target.value)

  let content = 
    (
      /* Else display content */
      <div className="m-0 h-[100vh] bg-slate-100 flex justify-center items-center">
        {/* The main container that has the shadow borders */}
        <div className="boxshadow flex justify-between items-center h-[80vh] lg:w-2/3 bg-slate-200 overflow-hidden">

          {/* Adjusting Logo position for mobile screen */}       
          <div className="relative lg:w-1/2 h-full lg:block md:hidden">
            <img className="absolute top-3 left-3" src={taqalogo} width={300} alt="logo" />
          </div>

          <div className="relative flex flex-col justify-center items-center lg:w-1/2 h-full bg-white px-10">

            {/* Left Container - Contains Logo */}
            <div className="lg:hidden md:flex justify-center items-center mb-8">
              <img src={taqalogo} width={256} alt="logo" />
            </div>

            {/* Right Container - Login Form */}              
            <form onSubmit={handleSubmit}>
              <h1 className="h1 mb-4">Login</h1>
              <p className="text-gray-600 mb-4">Please sign-in to your account to continue.</p>

              {/* Username Input */}
              <Input placeholder='Username' id="username" type="text" value={user} handleChange={handleUserInput} className={errMsg ? 'border-[#cb3030] text-[#cb3030]' : ''}/>
              <p className="text-[#cb3030] text-xs mb-4 mt-2">{errMsg}</p>

              {/* Password Input */}
              <div className="relative">
                <Input placeholder='Password' id="password" type={type} value={pwd} handleChange={handlePwdInput}/>
                <i className={`${icon ? 'bx bx-show' : 'bx bx-hide'} absolute z-[1000] right-2 top-[1.6rem] cursor-pointer text-[var(--primary-color)]`} onClick={handleToggle}/>
              </div>

              {/* Check the remember me to persist the user / Click on forgot password to change your password */}
              <div className="flex justify-between items-center mt-4">
                <Input type="checkbox" placeholder="Remember Me" id="checkbox" handleChange={(e) => setRememberMe(e.target.checked)}/>
                <Button path="/forgotpass" buttonText="Forgot Password?" className="transition ease duration-300 hover:text-[var(--primary-color)]"/>
              </div>

              {/* Login Button */}
              <Button type="submit" buttonText={isLoading ? "Loading..." : "Login"} className="button w-full mt-4"/>
            </form>
          </div>
        </div>
      </div>

    )

  return content
}

export default Login