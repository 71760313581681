import { setCredentials } from "../auth/authSlice";
import { setResetInfo } from "../auth/resetPassSlice";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Cookies from "universal-cookie";

const cookies = new Cookies();

// Login Handler
export const HandleLogin = async ({
  user,
  setUser,
  pwd,
  setPwd,
  setErrMsg,
  errRef,
  rememberMe,
  dispatch,
  apirequest,
  navigate,
  setSelectedSite,
}) => {
  // Path Endpoint of login API
  const path = "/session/user/v1/loginrequest";
  const body = {
    username: user,
    password: pwd,
    platform: "web",
  };

  try {
    const userData = await apirequest({ path, body }).unwrap();

    if (userData?.reply === "loginError") {
      setErrMsg(userData.message);
    } else {
      // Storing userdata in redux
      dispatch(
        setCredentials({
          userId: userData.userId,
          accessToken: userData.token,
          userData: userData,
        })
      );

      // Store all user data in local storage in case remember me is checked to persist user login
      if (rememberMe) {
        localStorage.setItem("_token", userData.token);
        localStorage.setItem("userId", userData.userId);
        localStorage.setItem("userData", JSON.stringify(userData));
      }

      userData?.userRoles?.forEach((role) => {
        role.roleProjects.forEach((project) => {
          project.projectSites.forEach((site) => {
            if (site.siteDefault === "A") {
              setSelectedSite(site.siteId);
            }
          });
        });
      });

      setUser("");
      setPwd("");
      navigate("/");
    }
  } catch (err) {
    if (!err?.originalStatus) {
      setErrMsg("No Server Response");
    } else if (err?.originalStatus.status === 400) {
      setErrMsg("Missing Username or Password");
    } else if (err?.originalStatus?.status === 401) {
      setErrMsg("Unauthorized");
    } else {
      setErrMsg("Login Failed");
    }
    if (errRef.current) {
      errRef.current.focus();
    }
  }
};

export const HandleForgotPass = async ({
  user,
  setUser,
  dispatch,
  apirequest,
  navigate,
}) => {
  const path = "/session/v1/passreset";
  const body = {
    username: user,
  };

  try {
    const resetReply = await apirequest({ path, body }).unwrap();

    // Displays a toaster notification depending on the reply message type
    if (resetReply.message.type === "error") {
      toast[resetReply.message.type](resetReply.message.text);
    } else {
      toast[resetReply.message.type](resetReply.message.text);
      dispatch(setResetInfo(resetReply));

      // Storing reset info in cookie to gain access to the change pass page for 5 minutes
      const expirationDate = new Date(Date.now() + 5 * 60 * 1000);
      cookies.set("resetInfo", resetReply, { expires: expirationDate });

      setUser("");

      // Navigate to change password page after 5s
      setTimeout(() => {
        navigate("/changepass");
      }, 500);
    }
  } catch (err) {
    if (!err?.originalStatus) {
      console.log(err);
    } else {
      console.log("Failed to send email.");
    }
  }
};

export const HandleChangePass = async ({
  otp,
  pass,
  setPass,
  setConfirmPass,
  resetInfo,
  apirequest,
  navigate,
}) => {
  const path = "/session/v1/passresetconfirm";
  const body = {
    passCode: otp,
    requestId: resetInfo.requestId,
    password: pass,
  };

  try {
    const resetReply = await apirequest({ path, body }).unwrap();

    if (resetReply.message.type === "error") {
      toast[resetReply.message.type](resetReply.message.text);
    } else {
      toast[resetReply.message.type](resetReply.message.text);

      setPass("");
      setConfirmPass("");

      setTimeout(() => {
        navigate("/");
      }, 1000);
    }
  } catch (err) {
    if (!err?.originalStatus) {
      console.log(err);
    } else {
      console.log(err);
    }
  }
};
